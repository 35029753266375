<template>
  <div class="profile-settings d-flexis">
    <!-- <profile-menu class="menu align-self-start" separated />  -->
    <div class="content flex-grow-1">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.menu {
  padding: 1rem;
  width: 220px;
  border-radius: 0.5rem;
}

.content {
  // display: flex;
  margin-right: 10vw;
  margin-left: 10vw;
  justify-content: center;
  align-items: center;
}
// .d-flexis {
// margin-right: auto;
// margin-left: auto;

// }
</style>
